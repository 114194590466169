/* 
  Some basic global styles
  Ideally, don't touch this file unless adding in some global css/browser overrides
*/

/* Box model reset */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  word-wrap: break-word;
  text-decoration: none;
}

/* Global fonts */
body {
  margin: 0;
  padding: 0;
  font-family: 'proxima-nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #333;
}

h1 {
  margin: 0px;
  font-weight: bold;
}

input {
  font-family: 'proxima-nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove browser outlines except for keyboard users for accessibility */
body:not(.keyboardUser) button:focus,
body:not(.keyboardUser) input:focus,
body:not(.keyboardUser) select:focus,
body:not(.keyboardUser) div:focus,
body:not(.keyboardUser) textarea:focus {
  outline: none;
}

/* Native search input overrides */
/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
